@import '../theme-bootstrap';
@import '../base/adaptive-placeholders';

input[type='text'],
input[type='email'],
input[type='password'],
input[type='date'],
input[type='search'],
input[type='tel'],
input[type='number'],
input[type='time'],
input[type='url'],
textarea {
  &.adpl--processed {
    border: 1px solid $color-cream-stroke;
    border-radius: 0;
    color: $color-gray;
    margin-bottom: 0;
    padding: 12px 16px;
    line-height: 24px;
    height: 48px;
    &:focus,
    &.active,
    &.adpl__mode-label {
      border-color: $color-black;
      color: $color-black;
    }
    @include breakpoint($landscape-up) {
      padding: 12px 24px;
    }
  }
}

// Setting Defaults for Adaptive Placeholder
$adaptive-placeholder: (
  height: 3em,
  margin: 1em,
  border-radius: 3px
);

// Calling adaptive placeholder for input.
.adpl {
  overflow: visible;
  input[type='email'],
  input[type='tel'],
  input[type='password'],
  input[type='text'],
  select,
  textarea {
    @include adaptive-placeholder(
      (
        height: map-get($adaptive-placeholder, height),
        margin: map-get($adaptive-placeholder, margin)
      )
    );
    border-color: $border-color-normal;
  }
  .select label {
    display: none;
  }
  fieldset {
    overflow: visible;
    max-width: 100%;
  }
  .form-item {
    position: relative;
    overflow: visible;
  }
}
